<template>
  <div class="head-container">
    <el-select
            v-model="query.transferType"
            clearable
            placeholder="转账类型"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in transferTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
      />
    </el-select>
    <el-select
            v-model="query.status"
            clearable
            placeholder="转账状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
      />
    </el-select>
    <el-input
            v-model="query.sourceInfo"
            clearable
            placeholder="转出客户姓名/ID/MT4"
            style="width: 240px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />
    <el-input
            v-model="query.targetInfo"
            clearable
            placeholder="转入客户姓名/ID/MT4"
            style="width: 240px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />

    <div class="filter-item">
      申请时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      transferTypeOptions:[
        {label: '转自持账户',value:1},
        {label: '转他人账户',value:2}
      ],
      statusOptions:[
        {label: '成功',value:1},
        {label: '失败',value:99}
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear');
    }
  }
}
</script>

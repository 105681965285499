<template>
  <div class="app-container">
    <eHeader :query="query" @clear="clear" @toQuery="toQuery" />
    <!--表格渲染-->
    <blockquote class="my-blockquote statistics-box">
      <div class="statistics-item">
        转账总额:${{statistics.amountSum}}
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        转自持: ${{statistics.supportSum}}
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        转他人: ${{statistics.othersSum}}
      </div>
    </blockquote>
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="oprLogin" label="转出账户">
        <template slot-scope="scope">
          <span>{{scope.row.sourceName}}({{scope.row.sourceId}})</span>
          <div>{{scope.row.sourceLogin}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="targetLogin" label="转入账户">
        <template slot-scope="scope">
          <span>{{scope.row.targetName}}({{scope.row.sourceId}})</span>
          <div>{{scope.row.targetLogin}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="金额($)"/>
      <el-table-column prop="transferType" label="转账类型">
      <template slot-scope="scope">
          <span>{{ scope.row.transferType==1?"转自持账户":"转他人账户" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="转账状态">
       <template slot-scope="scope">
          <span>{{ scope.row.status==1?"成功":"失败" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/finance/custmt4transfer/header'
export default {
  name:'transfer',
  components: { eHeader},
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      statistics:{
        amountSum: '',
        supportSum: '',
        othersSum: '',
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/tCustMt4Transfer'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const oprCustId = query.oprCustId

      const status = query.status
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      const transferType = query.transferType
      if (transferType!=null&&transferType!='') { this.params['transferType'] = transferType }
      if (oprCustId!=null && oprCustId!='') { this.params['oprCustId'] = oprCustId }

      if (status!=null && status!='') { this.params['status'] = status }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime };
      if(this.query.sourceInfo){
        this.params.sourceInfo = this.query.sourceInfo;
      }
      if(this.query.targetInfo){
        this.params.targetInfo = this.query.targetInfo;
      }
      return true
    },
    clear(){
      this.query = {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
